<template>
  <div class="viewer">
    <SelectField v-if="floorsOptions.length"
                 :options="floorsOptions"
                 :value="currentFloorIndex.toString()"
                 big
                 class="floor-select"
                 color="white"
                 @input="val => updateFloor(val)" />
    <div class="floor-container"
         @click="touchAction">
      <floor v-if="currentFloors.length"
             :ref="`floor${currentFloorIndex}`"
             :floor="currentFloors[currentFloorIndex]"
             :floorId="currentFloorIndex"
             class="floor" />
    </div>
  </div>
</template>

<script>
import Floor from '@/app/components/floors/floor.vue';
import SelectField from '@/app/components/inputs/select-field.vue';

export default {
  name: 'OAppStore',
  components: {
    Floor,
    SelectField,
  },
  data() {
    return {};
  },
  computed: {
    currentFloorIndex() {
      return this.$store.state.building.selectedFloor;
    },
    currentFloors() {
      return this.$store.state.building.selectedBuilding.next || null;
    },
    buildings() {
      return this.$store.state.building.collection;
    },
    floorsOptions() {
      return this.currentFloors.map((f, idx) => (f = { label: f.name, value: idx.toString() }));
    },
  },
  watch: {
    buildings(val) {
      if (val.length) {
        //TODO
        //Ulgy hack so the oasis-wifi-mapper display the qarnot building
        //APP-3880 / APP-3881
        let building = this.buildings.find(bld => bld.name === 'Qarnot');
        if (!building) building = this.buildings.find(bld => bld.name === 'Redmont');
        if (!building) building = this.buildings[0];
        this.$store.commit('building/setSelectedBuilding', building);
      }
    },
    currentFloors(val) {
      if (val.length) {
        this.updateFloorRotation();
      }
    },
    currentFloorIndex(val) {
      this.updateFloorRotation();
    },
  },
  mounted() {
    if (this.$q.platform.is.mobile) this.$store.dispatch('building/fetch');
  },
  methods: {
    touchAction(evt) {
      const spaceUuid = evt.target.id;
      if (!spaceUuid) return;
      if (typeof window.Android !== undefined && window.Android !== null && window.Android !== undefined) {
        const floor = this.$palmier.getFloor(this.currentFloorIndex);
        const point = floor.firstElementChild.createSVGPoint();
        const matrix = floor.firstElementChild.getScreenCTM().inverse();
        point.x = evt.clientX;
        point.y = evt.clientY;
        const svgP = point.matrixTransform(matrix);
        window.Android.touchAction(svgP.x, svgP.y, spaceUuid);
      }
    },
    updateFloor(idx) {
      this.$store.commit('building/setSelectedFloor', idx);
    },
    updateFloorRotation() {
      this.$nextTick(() => {
        const floorNode = this.$palmier.getFloor(this.currentFloorIndex);
        floorNode.style.top = `${50}px`;
        const floorSVG = floorNode.firstElementChild;
        floorSVG.style.transform = `scale(1) rotateZ(-90deg)`;
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.floor-select
  z-index 505
  font-size 3rem

.floor-container
  z-index 1
  .floor
    overflow hidden
    height auto
    pointer-events auto !important
</style>
